.date_time_wrapper{
    padding-left: 40px;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
}
.piller{
    width: 6px;
    position: absolute;
    left: 50%;
    height: 100%;
    background-color: #f0f0f0 !important;
    transform: translateX(-50%);
    border-radius: 20px;
}
.check_icon{
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    position: absolute;
    width: 45%;
}

@media only screen and (max-width: 1000px){
    .date_time_wrapper{
        padding-left: 0px;
    }
    .check_icon{
        width: 55% !important;
    }
}

@media only screen and (max-width: 500px) {
    .check_icon{
        width: 60%;
    }
    .date_time_wrapper{
        padding-left: 0px;
    }
}